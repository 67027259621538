// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';
@import "~@fortawesome/fontawesome-pro/css/all";

// Bootstrap
@import '~bootstrap/scss/bootstrap';



/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.pagination {
  li{
    cursor: pointer;
    small{
      font-size: 10px;
    }
  }
}