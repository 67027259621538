// Body
$body-bg: #f8fafc;
$primary: #182533;
$secondary: #75ABE8;
$text-gray: #B1B1B1;
$text-white: #FFFFFF;
// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
